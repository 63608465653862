@value colors: "../../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-error from colors;

.transfert {
  padding: 24px;
  padding-bottom: 0;
  box-sizing: border-box;
  min-height: calc(100vh - 79px - 48px);
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.link {
  all: unset;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: color-purple;
}

.content, .success {
  width: 100%;
  height: 100%;

  background-color: white;
  border-radius: 5px 0;

  margin-top: 24px;
  padding: 16px;
  box-sizing: border-box;
}

.content h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.label {
  margin-bottom: 8px;
}

.dropdown {
  width: 99%;
  margin-right: 1%;
}

.dropdown select {
  height: 42px;
  width: 100%;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form {
  margin-bottom: 16px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}

.formContainer {
  height: 100%;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.success {
  min-height: 75vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.button {
  all: unset;
  color: white;
  font-weight: 500;
  background-color: color-purple;
  height: 42px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-top: 16px;

  transition: .3s;
  cursor: pointer;
}

.button:hover {
  color: white;
  background-color: color-purple-dark;
}

.error {
  margin-top: 4px;
  font-size: 12px;
  color: color-error;
}

.SelectError {
  border-color: color-error !important;
}
