@value colors: "../../../components/library/style/colors.css";
@value color-error, color-red-bg from colors;

.list {
  list-style: none;
  padding-left: 0;
  margin: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 1rem;
}

.assemblyItem {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
  max-width:550px;
}

.assemblyItem h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.row {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.row p, .row b {
  font-size: 16px;
}

.notification {
  color: color-error;
  background-color: color-red-bg;
  padding: 8px;
  border-radius: 5px;

  display: flex;
  flex-direction: row;

  margin-bottom: 16px;
}

.notification p {
  margin: 0;
  margin-left: 16px;
}

.tools {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.tools button {
  margin-right: 16px;
}

.plan {
  text-align: center;
}

.confirmation {
  padding: 16px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media screen and (max-width: 400px) {
  .list {
    grid-template-columns: repeat(1, 1fr);
  }

  .row {
    flex-wrap: wrap;
  }

  .row p:first-child {
    margin: 16px 0 8px;
  }

  .row p:last-child {
    margin: 0 0 16px;
  }
}
