.assembly {
  padding: 24px;
  width: 100%;
}

.help {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.help p {
  margin-right: 4px;
}

.help a {
  text-decoration: none;
  font-weight: 500;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header h1 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;

  margin: 0;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.back {
  all: unset;
  color: black;
  margin-right: 16px;
  cursor: pointer;
}

.main {
  margin-top: 24px;
}

.content {
  display: grid;
  grid-template:
    "a b b" auto
    "a c c" auto / 1fr 1fr 1fr;
  grid-gap: 24px;
  margin-top: 24px;
}

.buttonGroup {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.buttonGroup button + button {
  margin-left: 8px;
}
