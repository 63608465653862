@value colors: "../../library/style/colors.css";
@value color-success, color-error, color-warning from colors;

.invitation {
  grid-area: a;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;

  width: 25%;
  min-width: 450px;
}

.map {
  width: 100%;
  height: 250px;
  border: none;
}

.assembly {
  padding: 16px;
}

.assembly h3 {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

.event {
  margin-top: 24px;

  display: grid;
  grid-template-columns: 1fr 5fr;
  row-gap: 16px;
  align-items: center;
}

.event p {
  margin: 0;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
}


.yes, .no, .represented {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 4px 8px;
  border-radius: 16px;

  font-weight: 500;
  min-width: 125px;
  box-sizing: border-box;

  margin-top: 8px;
}

.yes {
  background-color: color-success;
  color: white;
}

.no {
  background-color: color-error;
  color: white;
}

.represented {
  background-color: color-warning;
  color: white;
  min-width: 190px;
}

.icon {
  margin-right: 4px;
}

.response {
  margin: 0;
}

.responseGroup {
  display: flex;
  justify-content: flex-end;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
