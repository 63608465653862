@value colors: "components/library/style/colors.css";
@value color-purple, color-purple-dark, color-error from colors;

.content {
  padding: 16px;
  max-height: 500px;
  overflow-y: auto;
}

.success .content {
    padding-top: 32px;
}

.footer {
    background: #F7F7F7;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    padding: 16px 24px;
}

.footer button {
    margin-right: 16px;
}

.footer button:last-child {
    margin-right: 0;
}

.tertiaryButton {
    background-color: white;
    border: 1px solid #72757E;
    color: #72757E;
}

.tertiaryButton:hover {
    background-color: #F7F7F7;
}

.required, .requiredMargin {
  color: color-purple;
}

.content .title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin-top: 0;
}

.content.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content.error .title {
  margin-bottom: 0;
}

.content.error .description {
  margin-top: 8px;
}

.timetable {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.timetable div {
    margin-right: 16px;
}


.time input {
    border-radius: 5px;
    border: 1px solid #C4C4C4;
    height: 42px;
    padding: 8px;
    box-sizing: border-box;
}

.time p {
    margin-bottom: 8px;
}

.time input:hover {
    cursor: pointer;
    border-color: color-purple;
}
.time input:focus {
    border-color: color-purple;
    box-shadow: 0 0 6px #b1bdff;
    outline: none;
}

.shareholder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.list, .mailList {
    padding: 0;
    margin-top: 24px;
    margin-bottom: 24px;
    list-style: unset;
}

.mailList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.mailList li {
    list-style: unset;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: auto;
    max-width: 30%;
    margin-right: 8px;
    margin-top: 8px;

    border-radius: 50px;
    padding: 0 0 0 16px;

    background-color: color-purple;
    color: white;
}

.mailList li:hover {
    background-color: #7387fd;
    cursor: pointer;
}

.mail {
    font-size: 8px;
}

.profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
}

.profile img, .shareholderLogo {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    object-fit: cover;
    margin-right: 16px;
}

.shareholderLogo {
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(90deg, rgba(79,103,243,1) 0%, rgba(126,145,255,1) 100%);
    color: white;
}

.profile p {
    font-family: Roboto, sans-serif;
    font-weight: 700;
}

.requiredMargin {
    margin-right: 4px;
}

.hintError {
    color: color-error;
    font-size: 12px;
    margin-top: 4px;
}

.time .inputError {
    border-color: color-error;
}
