@value colors: "../../style/colors.css";
@value color-purple, color-text-subtle, color-purple-bg, color-error, color-error-light, color-error-dark from colors;

@value breakpoints: "../../style/breakpoint.css";
@value mobile, tablet-l from breakpoints;

.dropfile {
  border: 1px dashed #B0ADAD;
  border-radius: 5px;
  box-sizing: border-box;

  max-width: 265px;
  height: 165px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 14px;

  transition: .3s;
}

.error {
  border: 1px dashed color-error;
  border-radius: 5px;
  box-sizing: border-box;

  max-width: 265px;
  height: 165px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 14px;

  transition: .3s;
}

.dropfile.hover {
  border: 1px dashed color-purple;
  background-color: color-purple-bg;
}

.row {
  display: flex;
  justify-content: space-between;
}

.button {
  width: 48px;
  height: 48px;
  padding: 0 !important;
  min-width: 48px;
  margin-left: 4px;
}

.button span {
  margin-right: 0;
}

.button, .buttonFile, .dropzone {
  color: color-text-subtle;
  font-size: 14px;
  margin-top: 8px !important;
}

.buttonFile {
  padding: 8px 24px;
}

.illustration {
  width: 48px;
  height: 48px;
  min-height: 48px;
  border-radius: 48px;
  color: color-purple;
  background-color: color-purple-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}

.error .illustration {
  color: color-error-dark;
  background-color: color-error-light;
}

.error .description {
  font-size: 18px;
  margin-top: 8px;
}
.descriptionError {
  margin: 0;
  margin-top: 4px;
  padding: 4px 8px;
  background-color: color-error-light;
  border-radius: 5px;
  font-size: 13px;
  text-align: center;
}

.illustration.hover {
  font-size: 24px;
}

.label {
  margin-bottom: 8px;
}

.hint {
  color: color-text-subtle;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 14px;
}

.hintError {
  font-size: 14px;
  color: color-error;
  max-width: 265px;
}

.desktop {
  display: flex;
}

.onMobile {
  display: none;
}

.hintColumn {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.container {
  width: 258px;
}


.noMargin {
  margin-top: 4px;
  margin-bottom: 0;
}

.action {
  color: color-text-subtle;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  justify-content: flex-start;
  transition: .3s;
}

.action:hover {
  color: color-purple;
}

.description {
  text-align: center;
  color: color-text-subtle;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 8px !important;
}

.download {
  text-align: center;
  color: color-purple;
  font-size: 14px;
}
