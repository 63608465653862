.transfert {
  padding: 24px;
  padding-bottom: 0;
  box-sizing: border-box;
  min-height: calc(100vh - 79px - 48px);
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.link {
  all: unset;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: color-purple;
}

.content {
  width: 100%;
  height: 100%;

  background-color: white;
  border-radius: 5px 0;

  margin-top: 24px;
  padding: 16px;
  box-sizing: border-box;
}

.content h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  margin-top: 0;
  margin-bottom: 4px;
}

.dropdown {
  width: 33%;
}

.dropdown + .dropdown {
  margin-left: 16px;
}

.dropdown select {
  height: 42px;
  width: 100%;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px;
}

.button + .button {
  margin-left: 16px;
}

.content h4 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
}

.description {
  margin: 0;
}

.row {
  margin-top: 24px;
}

.link {
  cursor: pointer;
}
