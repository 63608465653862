@value colors: "components/library/style/colors.css";
@value color-text-subtle, color-purple-bg, color-purple from colors;

.marginTop {
    margin-top: 16px;
}

.link {
    all: unset;
    cursor: pointer;
}

.link:hover {
    color: black;
}

.linkContent {
    padding: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid color-purple-bg;
    margin-bottom: 8px;
    transition: .3s;
}

.linkContent:hover {
    background-color: color-purple-bg;
    color: color-purple;
    border: 1px solid color-purple;
}

.linkContent:hover .title, .linkContent:hover .period {
    color: color-purple;
}

.list {
    padding-left: 0;
    margin-top: 0;
}

.title {
    margin: 0;
    color: black;
}

.period {
    margin: 0;
    font-size: 14px;
    color: color-text-subtle;
}
