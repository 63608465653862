@value colors: "components/library/style/colors.css";
@value color-text-subtle, color-purple, color-purple-dark, color-purple-bg from colors;

@value breakpoints: "components/library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.container {
    padding: 16px;
    box-sizing: border-box;

    background-color: white;
    border-radius: 5px;
}

.content {
    height: 100%;

    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}

.link {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4F67F3;

    text-decoration: none;
}

.list {
    list-style: none;
    padding-left: 0;
}

.list .item {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.item {
  border-radius: 5px;
  padding: 4px;
  margin-bottom: 8px;
  margin-right: 0;
  font-weight: 400;
  text-align: left;
  border: 1px solid color-purple-bg;
  width: 100%;
  transition: .3s;
}

.item:hover {
    background-color: color-purple-bg;
    border: 1px solid color-purple-bg;
}

.item p {
  margin: 0;
}

.item .title {
    font-weight: 500;
    font-size: 16px;
}

.item .date {
    color: color-text-subtle;
}

.buttonGroup {
    display: flex;
    flex-direction: column;
}

.buttonGroup button:first-child {
    margin-bottom: 8px;
}

.iconContainer {
  background-color: white;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    margin-right: 8px;
}

@media screen and (max-width: tablet-l) {
    .container { grid-area: e }
}
