
.power {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  background-color: #00000096;
}

.popup {
  width: 50%;
  max-width: 900px;
  min-width: 320px;
  padding: 24px;
  box-sizing: border-box;

  background-color: white;
  border-radius: 5px;
}

.popup h2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
}

.buttonGroup button + button {
  margin-left: 8px;
}

.notification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dropdown {
  width: 100%;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  height: 42px;
  text-transform: uppercase;
}

.dropdown option {
  text-transform: uppercase;
}

.label {
  margin-bottom: 4px;
}
