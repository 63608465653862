@value colors: "components/library/style/colors.css";
@value color-gray, color-text-subtle, color-purple from colors;

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.title {
  font-weight: 500;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

.description {
  margin-top: 8px;
}

.navigation {
  padding-bottom: 1rem;
  display: flex;
  column-gap: 1rem;
}

.navigation button {
  color: color-text-subtle;
  font-weight: 500;
  font-size: 16px;
  padding: 0;
}

.table {
  width: 100%;
  margin-top: 16px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid color-gray;
}

.row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
  padding: 0.5rem;
  border-bottom: 1px solid color-gray;
  box-sizing: border-box;
}

.row p {
  margin: 0;
  font-weight: 500;
}

.empty {
  padding: 1rem;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table .title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 1rem;
  text-align: center;
  color: color-text-subtle;
}

.navigation .active {
  color: color-purple;
}
