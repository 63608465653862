@value colors: "../../library/style/colors.css";
@value color-purple, color-purple-bg from colors;

@value breakpoints: "../../library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.hide {
  display: block;
}

.show {
  display: block;
}

.chat {
  width: 40%;
  min-width: 320px;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  padding-bottom: 16px;
  margin-right: 16px;
}

.searchbarContainer {
  padding: 16px;
  padding-bottom: 0;
}

.searchbarContainer > div {
  width: 100%;
}

.listButton {
  width: 100%;
  margin-top: 16px;
  padding: 16px;
  padding-bottom: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 75px;
  padding: 0;

  transition: .3s;
}

.contact:hover, .contact:focus {
  background-color: color-purple-bg;
}

.colorLine {
  background-color: transparent;

  width: 4px;
  height: 100%;

  transition: .3s;
}

.contact:hover .colorLine, .contact:focus .colorLine {
  background-color: color-purple;
}

.contactInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.contactLogo, .contactPicture {
  width: 48px;
  height: 48px;

  border-radius: 5px;

  margin-right: 16px;
  margin-left: 16px;
}

.contactPicture {
  object-fit: cover;
}

.contactLogo {
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(90deg, rgba(79,103,243,1) 0%, rgba(126,145,255,1) 100%);
    color: white;
}

.list {
  padding-left: 0;
}

.notification {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  border-radius: 4px;

  background-color: color-purple;
  color: white;
  font-size: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-size: 12px;
  color: #72757E;
  text-align: left;
  margin-top: 0;
}

.subtitle {
  text-align: left;
  margin-bottom: 0;
}

.support {
  padding: 16px;
  padding-bottom: 0;
}

.connection {
  position: relative;
}

.online, .offline {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  border: 4px solid white;

  bottom: -4px;
  right: 8px;
}

.contact:hover .online, .contact:hover .offline {
  border-color: color-purple-bg;
}

.contact:focus .online, .contact:focus .offline {
  border-color: color-purple-bg;
}

.online {
  background-color: #00c773;
}

.offline {
  background-color: red;
}

@media screen and (max-width: tablet-l) {
  .hide {
    display: none;
  }

  .chat {
    width: 100%;
  }
}
