.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row > div {
  width: 49%;
}

.form h4 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 0;
}

.form {
  row-gap: 0;
}

.button {
  margin-top: 16px;
}
