@value colors: '../../library/style/colors.css';
@value color-gray from colors;

.container {
    padding: 16px;
}

.footer {
    padding: 16px;
    border-top: 1px solid color-gray;

    display: flex;
    justify-content: flex-end;
}

.title {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
    margin-top: 8px;
}

.container.success, .container.error {
    margin-top: 16px;
}

.container.fail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.description {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 18px;
}

.container.fail > div {
    margin: auto;
}

.select {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.select label {
    margin-bottom: 4px;
}

.select select {
    padding: 8px 4px;
    border-radius: 5px;
    border-color: color-gray;
    width: max-content;
}
