@value colors: "../../../components/library/style/colors.css";
@value color-gray, color-gray-light, color-purple, color-text-subtle from colors;

.choices {
  padding: 24px 24px 0;
  box-sizing: border-box;
  height: calc(100vh - 79px - 48px);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.link {
  all: unset;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: color-purple;
}

.content {
  width: 100%;
  height: 100%;

  background-color: white;
  border-radius: 5px 0;

  margin-top: 24px;
  padding: 16px;
  box-sizing: border-box;
}

.content h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.content form {
  max-width: 500px;
  margin: 8px 0 32px;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
}

.checkboxContainer > div:first-child {
  margin-right: 16px;
}

.selection {
  padding: 4px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 5px;
  border-color: #CCCCCC;
  cursor: pointer;
}

.disabledInput {
  border-radius: 5px;
  border: 1px solid color-gray;
  height: 42px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: color-gray-light;
}

.label {
  margin-bottom: 8px
}

.steps, .step {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.steps { column-gap: 32px; }

.number {
  background-color: #d2d2d2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin-right: 8px;
  padding: 8px;
  box-sizing: border-box;
  font-weight: 500;
  color: color-text-subtle;
}

.active .number {
  color: white;
  background-color: color-purple;
}

.steps p {
  margin: 0;
  font-weight: 500;
  color: color-text-subtle;
}

.step.active {
  color: black;
}
