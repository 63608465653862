.preview {
  width: 100%;
}

.content {
  padding: 32px;
  max-width: 800px;
}

.content h2 {
  margin-bottom: 16px;
}

.period {
  margin-top: 0;
}

.content h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section {
  margin-top: 24px;
}
