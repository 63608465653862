@value colors: "components/library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-bg from colors;

@value breakpoints: "components/library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.header {
    background-color: white;
    border-bottom: 1px solid #E4E4E4;

    padding: 0 2.5%;
    height: 75px;
    margin: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    min-width: 45%;
}

.buttonGroup button:first-child {
    margin-right: 8px;
}

.header h1 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    text-transform: capitalize;
    margin-right: 8px;
}

.headerRow {
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerBack {
    margin-right: 16px;
    color: black;
}

.link {
    all: unset;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: black;
    transition: .3s;
    cursor: pointer;
}

.link.active, .link:hover {
    color: color-purple;
}

.separator {
    border-right: 1px solid #E4E4E4;
    height: 70%;
    margin: 0 24px;
}

.navigationPlaceholder {
  width: 45%;
}

.link + .link {
  margin-left: 24px;
}

@media screen and (max-width: desktop) {
  .navigationPlaceholder {
    width: 30%;
  }
}

@media screen and (max-width: laptop) {
  .navigationPlaceholder {
    width: 10%;
  }
}

@media screen and (max-width: tablet-l) {
  .navigationPlaceholder {
    display: none;
  }

  .buttonGroup {
    min-width: 60%;
  }

  .buttonGroup button {
    font-size: 14px;
    padding: 0;
  }

  .separator {
    display: none;
  }
}

@media screen and (max-width: mobile) {
  .title {
    display: none;
  }

  .header {
    padding-left: 75px;
    width: calc(100% - 75px);
  }

  .headerRow {
      overflow: hidden;
  }

  .active {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }

}
