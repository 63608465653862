@value colors: "../../components/library/style/colors.css";
@value color-gray, color-gray-light, color-text-subtle from colors;

.container {
    width: 100%;
}

.header, .main {
 padding: 24px;
}

.header h1 {
    margin: 0;

    font-size: 24px;
}

.list {
    padding: 0;
    list-style: none;

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 48px;
}

.list .item {
    width: 100%;
}

.society {
    width: 100%;
}

.society img {
    width: 100%;
    max-height: 150px;
    overflow: hidden;
    object-fit: cover;
    margin-bottom: 8px;
    border-radius: 5px;
}

.header {
    height: 15%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main {
    width: 100%;
    background-color: white;
    height: 85%;
    box-sizing: border-box;
}

.description {
    margin-bottom: 0;
}

.item {
    border: 1px solid color-gray;
    border-radius: 5px;
    padding: 8px;
    transition: .3s;
}

.item:hover {
    background-color: color-gray-light;
    cursor: pointer;
}

.name {
    margin: 0;
    font-weight: 500;
}

.owner {
    margin: 0;
    color: color-text-subtle;
}

.empty {
    width: 100%;
    min-height: 50vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.empty p {
    font-size: 36px;
    font-weight: 500;
    color: color-gray
}
