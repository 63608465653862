@value colors: "../../../components/library/style/colors.css";
@value color-purple-bg, color-purple from colors;

.container {
  width: calc(100% - 275px);
}

.section {
  padding: 24px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.header h2 {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}

.center {
  width: 100%;
  height: 65vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center h3 {
  color: #8E8E8E;
}

.documentsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  background-color: white;
  margin-top: 16px;
  padding: 16px;
}

.documentButton {
  background-color: color-purple-bg;
  color: color-purple;
  font-size: 36px;;
  width: 115px;
  height: 100px;
  border-radius: 5px;
}

.documentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 16px;
}

.documentTitle {
  margin-top: 4px;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  max-width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list {
  list-style: none;
  padding-left: 0;
}
