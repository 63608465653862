@value colors: "../../components/library/style/colors.css";
@value color-text-subtle, color-purple from colors;

.admin {
    width: 100%;
    height: 100vh;
}

.admin header {
    padding: 24px 24px 0 24px;
    box-sizing: border-box;
}

.row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.description h1 {
    margin: 0;
    font-size: 24px;
}

.description p {
    margin-top: 8px;
    color: color-text-subtle;
}

.input {
    display: flex;
    flex-direction: row;
    background-color: white;

    border-radius: 25px;
    border: 1px solid white;
    padding: 8px 16px;
    overflow: hidden;

    transition: .3s;
}

.input:focus-within, .input:hover {
    border: 1px solid color-purple;
}

.input:focus-within .icon {
    color: color-purple;
}

.input input {
    border: none;
    outline: none;
}

.input .icon {
    color: color-text-subtle;
    transition: .3s;
}

.navigation {
    height: 48px;
    padding: 0;
    color: color-text-subtle;
    border-bottom: 2px solid transparent;
    transition: .3s;
}

.navigation.active, .navigation:hover {
    color: black;
    border-bottom: 2px solid color-purple;
}

.navigation + .navigation {
    margin-left: 16px;
}

.admin main {
    padding: 24px;
    box-sizing: border-box;
}

.admin main {
    width: 100%;
    max-height: 80vh;
    background-color: white;
}