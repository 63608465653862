@value colors: "../../library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-bg, color-text-subtle from colors;

.table {
  max-width: 1200px;
}
.table h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #72757E;
    margin-bottom: 8px;
}

.rowConfirmation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px;
}


.row {
    padding: 8px;
    display: grid;
    grid-template-columns: 3fr 1fr 2fr;
}

.row4 {
    padding: 8px;
    display: grid;
    grid-template-columns: 2fr 3fr 1fr 2fr;
}

.rowItem:hover {
    background-color: color-purple-bg;
    border-radius: 5px;
}

.cellTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cellTitle p {
    font-weight: 500;
}

.cellTitle button {
    font-size: 14px;
    margin-left: 8px;
}

.list {
    padding-left: 0;
}

.toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.toolbar a {
    color: black;
    margin-left: 16px;
}

.star {
    color: color-purple;
}

.preview {
    margin-left: 16px;
}

.preview:hover, .download:hover {
    color: color-purple;
}

.description {
    color: color-text-subtle;
    font-style: italic;
}

.value {
    overflow: hidden;
    text-overflow: ellipsis;
}

.item.skeleton {
    background-color: #E8E8E8;
    border-radius: 5px;
    padding: 8px;
    transition: .3s;
    margin-bottom: 8px;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: skeleton;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    list-style: none;
}

.item.skeleton:hover {
    cursor: default;
    background-color: #E8E8E8;
    border: none;
}

@keyframes skeleton {
    0% {
        background-color: #E8E8E8;
    }
    50% {
        background-color: #D8D8D8;
    }
    100% {
        background-color: #E8E8E8;
    }
}

@media screen and (max-width: 405px) {
    .date { display: none; }

    .row { grid-template-columns: repeat(2, 1fr); }
}
