@value colors: "components/library/style/colors.css";
@value color-purple-bg, color-purple, color-purple-dark, color-error, color-error-light, color-error-dark from colors;

.form {
  margin: 16px 0;
}

.waiting {
  display: flex;
  flex-direction: column;
}

.waiting .hint {
  font-weight: 500;
}

.shareholder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selected {
  padding: 16px;
  background-color: color-purple-bg;
  border-radius: 5px;
  margin: 8px 0;
}

.title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.button {
  padding: 0;
  color: color-purple;
  font-size: 16px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;

  list-style: none;
  padding-left: 0;
}


.success {
  max-width: 750px;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.success h3 {
  text-align: center;
}
.success a {
  margin-top: 16px;
  text-decoration: none;
  height: 48px;
  box-sizing: border-box;
  background-color: color-purple;
  color: white;
  padding: 8px 24px;
  border-radius: 5px;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: .3s;
}

.success a:hover {
  color:white;
  background-color: color-purple-dark;
}

.error, .default {
  font-weight: 500;
}

.error {
  color: color-error;
}

.available {margin-bottom: 0}
.hint {
  margin-top: 0;
  font-size: 14px;
  color: color-error;
}

.outline {
  text-decoration: none;
  height: 48px;
  box-sizing: border-box;
  background: none;
  border: 2px solid color-purple;
  color: color-purple;
  padding: 8px 24px;
  border-radius: 5px;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: .3s;
  margin-right: 16px;
}

.outline:hover {
  background-color: color-purple-bg;
  color: color-purple-dark;
  border-color: color-purple-dark;
}

.grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  align-items: center;
  column-gap: 16px;

  list-style: none;
  margin: 0;
  padding: 8px 0;
  border-bottom: 1px solid #C4C4C4;
}

.gridTitle {
  font-weight: 500;
}

.number {
  text-align: right;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.buttonGroup button + button {
  margin-left: 16px;
}

.error {
  background-color: color-error-light;
  color: color-error-dark;
  padding: 4px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.error .icon {
  margin-right: 8px;
}

.create {
  margin-bottom: 8px;
}

.footer {
  margin-top: 16px;
}
