.success {
  max-width: 750px;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.success button {
  margin-top: 16px;
}

.label {
  margin-bottom: 4px;
}

.dropdown {
  border-color: #C4C4C4;
  border-radius: 5px;
  padding: 4px 24px 4px 4px;
  height: 48px;
  margin-bottom: 24px;
  cursor: pointer;
}

.form button {
  margin-top: 24px;
}

.form {
  max-width: 700px;
}
