@value colors: "../../../components/library/style/colors.css";
@value color-red-bg, color-error, color-gray, color-purple from colors;

.gridContainer {
  overflow-x: scroll;
}
.grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2.5fr;
  grid-gap: 24px;
  align-items: center;

  background-color: white;
  padding: 8px;
  border-radius: 5px;

  margin-bottom: 8px;
  height: 48px;
  min-width: 1440px;
}

.grid > div {
  display: block;
}

.grid p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gridTitle p {
  font-family: Roboto, sans-serif;
  font-weight: 500;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 48px;
}

.buttonGroup button + button {
  margin-left: 16px;
}

.grid p {
  text-align: left;
}

.number {
  text-align: right !important;
}

.text {
  text-align: left !important;
}

.buttonGroup .margin {
  margin-right: 8px;
}

.errorHandler {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background-color: color-red-bg;
  border-radius: 5px;
  padding: 8px;
  color: color-error;

  max-width: 450px;

  margin-bottom: 16px;
}

.icon {
  margin-right: 16px;
}

.errorHandler p {
  margin: 0;
  text-align: left;
}

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: white;
  border-radius: 5px;
  padding: 16px;

  height: 65vh;
  text-align: center;
}

.success h3 {
  width: 60%;
}

.represented select {
  height: 42px;
  padding: 8px;
  border-radius: 5px;

  border-color: color-gray;
  transition: .3s;

  width: 100%;
  box-sizing: border-box;
}

.represented select:hover {
  border-color: color-purple;
  cursor: pointer;
}

.represented select:focus {
  outline: none;
}
