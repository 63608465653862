@value breakpoints: "../../library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.content {
    padding: 0 24px;
}

.content h3 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}


.footer {
    background: #F7F7F7;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    padding: 16px 24px;
}

.footer button:first-child {
    margin-right: 16px;
}

.tertiaryButton {
    background-color: white;
    border: 1px solid #72757E;
    color: #72757E;
}

.tertiaryButton:hover {
    background-color: #F7F7F7;
}

.success, .error {
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error .title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  margin: 0;
}

.error .description {
  margin-top: 8px;
  margin-bottom: 40px;
  text-align: center;
}

.success p {
    font-size: 18px;
    margin-top: 0;
}
