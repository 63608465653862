@value colors: "../../library/style/colors.css";
@value color-text-subtle, color-purple, color-purple-dark, color-purple-bg from colors;

@value breakpoints: "../../library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
}

.favorite {
    align-self: flex-start;
    font-size: 14px;

    margin-bottom: 8px;
}

.documentButton p {
    width: 95%;
}

.documentButton {
    all:unset;

    width: 155px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: .3s;

    margin-top: 8px;
}

.documentButton:hover {
    cursor: pointer;
}

.list .documentButton {
    margin-right: 8px;
}

.list .documentButton:last-child {
    margin-right: 0;
}

.selection h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #72757E;
    margin-bottom: 0;
}

.documentIcon {
    font-size: 24px;
    margin-bottom: 8px;
}

.documentTitle {
    text-align: left;
    font-size: 14px;
    margin: 0;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
}

.documentDate {
    font-size: 14px;
    margin-top:0;
    margin-bottom: 8px;
    color: color-text-subtle;
}
.item {
    background-color: white;
    border-radius: 5px;
    padding: 8px;
    transition: .3s;
    margin-bottom: 8px;
}

.item:hover {
    cursor: pointer;
    background-color: color-purple-bg;
    border: 1px solid color-purple;
}

.item.skeleton {
    background-color: #E8E8E8;
    border-radius: 5px;
    padding: 8px;
    transition: .3s;
    margin-bottom: 8px;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: skeleton;
    width: 155px;
    height: 155px;
}

.item.skeleton:hover {
    cursor: default;
    background-color: #E8E8E8;
    border: none;
}

@keyframes skeleton {
    0% {
        background-color: #E8E8E8;
    }
    50% {
        background-color: #D8D8D8;
    }
    100% {
        background-color: #E8E8E8;
    }
}

.list .item {
    margin-right: 8px;
}

.list .item:last-child {
    margin-right: 0;
}

.fill, .star {
    color: color-purple;
}

@media screen and (max-width: mobile) {
  .list {
    flex-direction: column;
    margin-top: 0;
  }

  .list .item {
    margin-right: 0;
  }

  .documentButton {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 8px;
    width: 100%;
    height: unset;
    box-sizing: border-box;
  }

  .documentIcon {
    margin-bottom: 0;
    font-size: 16px;
  }

  .documentTitle {
    margin-top: 0;
    font-weight: 500;
  }
}
