@value colors: "components/library/style/colors.css";
@value color-purple from colors;

.category {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.category button, .icon {
  font-size: 12px;
}

.categorySelector {
  width: 24px;
  height: 24px;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  margin-right: 8px;
  cursor: pointer;
  transition: .3s;
  color: white;
}

.categorySelector:hover, .categorySelector:focus, .categorySelector.selected {
  border-color: color-purple;
}

.categorySelector.selected {
  background-color: color-purple;
}

.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter:not(:last-child) {
  margin-bottom: 8px;
}

.filterDropdown {
  position: absolute;

  background-color: white;
  box-shadow: 0px 0px 16px 8px rgba(39, 48, 101, 0.04);

  padding: 8px;
  border-radius: 5px;

  margin: 0;
}

.filter p {
  margin: 0;
}
