@value colors: "components/library/style/colors.css";
@value color-purple, color-purple-dark from colors;

.capitalisation {
  padding: 16px 24px 0 24px;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
}

.empty h3 {
  margin-bottom: 8px;
}

.row, .tools {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row {
  justify-content: space-between;
}

.tools {
  justify-content: flex-end;
}

.tools {
  width: 55%;
  max-width: 500px;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;

  margin: 0;
}

.dropdown {
  height: 48px;
  border-radius: 5px;
  margin-right: 16px;
  padding: 0 8px;
  border-color: #E4E4E4
}

.tools div {
  margin-right: 0;
}

.tools button {
  margin-left: 16px;
}

.date {
  margin: 0;
}

.links {
  font-size: 16px;
  padding: 0;
  color: color-purple;
  transition: .3s;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.links:hover {
  color: color-purple-dark;
}

.historyList {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 0;

  background-color: white;
  box-shadow: 0 0 16px 8px rgba(39, 48, 101, 0.04);

  padding: 8px;
  border-radius: 5px;
}

.historyButton {
  font-size: 14px;
  text-align: left;
  margin-top: 0;
  transition: .3s;
}

.historyButton + .historyButton {
  margin-top: 8px;
}

.historyButton:hover {
  color: color-purple;
}

@media screen and (max-width: 400px) {
  .tools {
    display: none;
  }
}
