.chat {
  width: 100%;
}

.content {
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 75px;
}

.contact:hover {
  background-color: color-purple-bg;
}
