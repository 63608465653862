@value colors: "../../../../components/library/style/colors.css";
@value color-text-subtle, color-gray, color-purple from colors;

.back {
  padding: 0;
  min-width: unset;
}

.title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 8px;
}

.description {
  margin-top: 8px;
  color: color-text-subtle;
}

.add {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem;
  border: 1px solid color-gray;
  border-radius: 5px;
  width: 320px;
  transition: 0.3s;
}

.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
}

.icon {
  padding: 0.5rem;
  border: 1px solid color-gray;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.icon svg {
  color: color-text-subtle;
  transition: 0.3s;
}

.add:hover,
.add:hover span,
.add:hover svg {
  color: color-purple;
  border-color: color-purple;
}

.add.selected,
.add.selected span,
.add.selected svg {
  color: color-purple;
  border-color: color-purple;
}
