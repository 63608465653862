@value colors: "components/library/style/colors.css";
@value color-purple, color-purple-bg from colors;

@value breakpoints: "components/library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.container {
    width: 100%;

    display: flex;
    flex-direction: row;
}

.navigation {
    height: 100vh;
    width: 275px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: #FFFFFF;
    box-shadow: 0 0 16px 8px rgba(39, 48, 101, 0.04);

    position: sticky;
    top: 0;
}

.list {
    list-style: none;
    padding-left: 0;
}

.selected, .link:hover {
    color: color-purple !important;
    background-color: color-purple-bg;
    outline: none;

    display: flex;
    justify-content: space-between;
}

.link, .logOut {
    width: 100%;
    height: 48px;
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    box-sizing: border-box;

    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #000000;

    transition: .3s;
}

.logo {
    font-family: Fjalla One, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 110.69%;
    color: #000000;
    padding-left: 24px;
    margin-bottom: 0;
}

.logo span {
    font-family: Fjalla One, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: color-purple;
}

.caption {
    padding-left: 24px;
    margin-top: 0;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.icon {
    margin-right: 8px;
    color: #C4C4C4;
    transition: .3s
}

.selected .icon, .link:hover .icon {
    color: color-purple;
}

.linkContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.arrowIcon {
    opacity: 0;
}

.selected .arrowIcon {
    opacity: 1;
}

.logOut:hover, .logOut:hover .icon {
    color: color-purple;
}

.menuButton {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 48px;
    height: 48px;
    z-index: 100;

    display: none;
    align-items: center;
    justify-content: center;

    border-radius: 5px;

    background-color: color-purple;
    color: white;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.menuOpenButton {
    display: none;
    align-items: center;
    justify-content: center;
    margin-right: 24px;

    border-radius: 5px;

    width: 48px;
    height: 48px;

    background-color: color-purple;
    color: white;
}

.dashboard {
    width: 90%;
    margin: auto auto 8px;
    box-sizing: border-box;
}

@media screen and (max-width: tablet-l) {
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;
        width: 100%;
        height: 100vh;
        background-color: #0000009e;
    }
    .menuButton {
        display: flex;
    }
    .navigation.show {
        position: fixed;
        z-index: 100;
        max-width: 320px;
        top: 0;
        opacity: 0;
        animation-name: slideInLeft;
        animation-duration: 450ms;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }

    .navigation.hide {
        position: fixed;
        z-index: 100;
        max-width: 320px;
        top: 0;
        opacity: 1;
        animation-name: slideOutLeft;
        animation-duration: 450ms;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
    }
}

@media screen and (max-width: mobile) {
    .navigation {
        width: 100%;
    }
    .menuOpenButton {
        display: flex;
    }
}

@keyframes slideInLeft {
    0%{
        opacity: 0;
        transform: translateX(-600px);
    }
    50%{
        opacity: 0.5;
        transform: translateX(-300px);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOutLeft {
    0%{
        opacity: 1;
        transform: translateX(0);
    }
    50%{
        opacity: 0.5;
        transform: translateX(-300px);
    }
    100%{
        opacity: 0;
        transform: translateX(-600px);
    }
}
