@value colors: "components/library/style/colors.css";
@value color-purple, color-purple-bg, color-purple-dark from colors;

.content {
  width: 100%;
  height: 100%;

  background-color: white;
  border-radius: 5px 0;

  margin-top: 24px;
  padding: 16px;
  box-sizing: border-box;
}

.content h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.content form {
  min-width: 500px;
  width: 100%;
  margin: 32px 0;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.creationButton {
  text-align: left;
  white-space: nowrap;
  font-size: 16px;
  height: 32px;
}

.label {
  margin-top: 0;
  margin-bottom: 4px;
}

.dropdown {
  width: 49%;
}

.dropdown + .dropdown {
  margin-left: 16px;
}

.dropdown select {
  height: 42px;
  width: 100%;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px;
}

.content form {
  max-width: 700px;
  margin: 24px 0;
}

.button + .button {
  margin-left: 16px;
}

.label {
  margin-top: 0;
}

.label.error {
  color: red;
  font-size: 14px;
}

.ghostButton {
  all:unset;
  height: 48px;
  border: 2px solid color-purple;
  color: color-purple;
  cursor: pointer;
  transition: .3s;
  border-radius: 5px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  padding: 4px 24px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 18px;
}

.ghostButton:hover {
  background-color: color-purple-bg;
  border-color: color-purple-dark;
  color: color-purple-dark;
}

.success {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success h2 {
  margin-top: 4px;
}

.quantity p {
  margin-top: 0;
  margin-bottom: 4px;
}

.quantity {
  min-width: 320px;
}
