@value colors: "../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-bg from colors;

@value breakpoints: "../../components/library/style/breakpoint.css";
@value mobile from breakpoints;

.feedback {
    width: 100%;
}

.header {
    background-color: white;
    border-bottom: 1px solid #E4E4E4;

    padding: 0 2.5%;
    width: 100%;
    box-sizing: border-box;
    height: 75px;
    margin: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.header a {
    all: unset;
}

.header h1 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.separator {
    border-right: 1px solid #E4E4E4;
    height: 70%;
    margin: 0 24px;
}

.content {
    padding: 24px 48px;
}

.content h2 {
    margin-top: 0;
}

.passive {
    color: black;
    transition: .3s;
}

.passive:hover {
    color: color-purple-dark;
}

.row {
    display: flex;
    flex-direction: row;
}

.row div {
    margin-right: 16px;
}

.input {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.input button {
    max-width: 250px;
}

.success {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: mobile) {
    .header h1, .separator {
        display: none;
    }
    .header {
        justify-content: flex-end;
    }

    .header button:first-child {
        margin-right: 8px;
    }

    .content {
        padding: 16px;
    }
    .row {
        flex-direction: column;
    }

    .row div {
        margin-right: 0;
        margin-bottom: 16px;
    }
}
