.settings {
    width: 100%;
}

.header {
    background-color: white;
    border-bottom: 1px solid #E4E4E4;

    padding: 0 2.5%;
    width: 100%;
    box-sizing: border-box;
    height: 75px;
    margin: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.header h1 {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.separator {
    border-right: 1px solid #E4E4E4;
    height: 70%;
    margin: 0 24px;
}

.content {
    padding: 24px 48px;
}

.content h2 {
    margin-top: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
}

.title {
    margin-bottom: 8px;
}

.row {
    display: flex;
    font: row;
    margin-bottom: 24px;
}

.row div:first-child {
    margin-right: 24px;
}

.hr {
    margin-bottom: 24px;
}

.marginTop {
    margin-top: 32px;
}

.button {
    margin-top: 24px;
}

.toast {
    position: fixed;
    top: 24px;
    min-width: 315px;
    right: 50%;
    left: 50%;
    background-color: #00c773;
    padding: 8px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 500;
}

.toastIcon {
    margin-right: 8px;
}

.profilePicture {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    object-fit: cover;

    margin-right: 16px;
}
