@value colors: "components/library/style/colors.css";
@value color-text-subtle from colors;

.onboarding {
  font-size: 20px;
}

.description {
  color: color-text-subtle;
  margin-top: 0;
}
