@value colors: "../../library/style/colors.css";
@value color-purple, color-purple-dark from colors;

.message {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  background-color: #00000096;
}

.popup {
  width: 50%;
  max-width: 900px;
  min-width: 320px;
  padding: 24px;
  box-sizing: border-box;

  background-color: white;
  border-radius: 5px;
}

.popup h2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.buttonGroup button + button {
  margin-left: 8px;
}

.notification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
