.content {
  margin: 32px 0;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 0 24px;
  margin: 0 0 16px;
}
