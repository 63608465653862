body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F5F7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul, li {
    font-family: Roboto, sans-serif;
}

.ck {
  background-color: white;
  border-radius: 5px;
}

.ck-content {
  min-height: 250px;
}

.ck.ck-editor__editable_inline {
  border: 1px solid #C4C4C4 !important;
}

.button {
  all: unset;
  height: 48px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 16px;
  max-width: 300px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  background-color: #4F67F3;
  color: white;
}

.button:hover {
  color: white;
  background-color: #404FA1;
}

.button:focus {
  background-color: #7D8FF5;
}

.button + .button {
  margin-left: 16px;
}
