@value colors: "../../../components/library/style/colors.css";
@value color-purple, color-red-bg, color-success, color-success-light, color-success-dark, color-error from colors;

.section {
  width: 100%;
}

.main {
  margin: 16px;
}

.content {
  background-color: white;
  padding: 16px;
  border-radius: 5px;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-top: 0;
}

.form {
  max-width: 500px;
}

.hint {
  font-size: 14px;
  font-weight: 500;
  color: color-purple;
  margin-top: 0;
  margin-left: 32px;
}

.checkbox {
  margin-bottom: 16px;
}

.buttonGroup {
  max-width: 650px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 48px;
}

.errorHandler {
  background-color: color-red-bg;
  color: color-error;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 8px;
  border-radius: 5px;
  max-width: 500px;
  box-sizing: border-box;
}

.icon {
  margin-right: 16px;
  font-size: 24px;
}

.errorHandler p {
  margin: 0;
  font-weight: 500;
}

.edition {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form {
  width: 65%;
  min-width: 700px;
}

.info {
  width: 40%;
  min-width: 320px;
}

.label {
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 500;
}

.subtitle {
  font-weight: 500;
  margin-bottom: 16px;
}

.margin {
  margin-top: 24px;
  margin-bottom: 8px;
}

.floatButton {
  position: fixed;
  bottom: 24px;
  right: 24px;
}

.loading {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification {
  background-color: color-success-light;
  color: color-success-dark;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 750px;
  border-radius: 5px;
}

.notification p {
  margin: 0;
}

.success {
  background-color: white;
  padding: 16px;
  border-radius: 5px;
  height: 50vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.success h3 {
  width: 60%;
  margin-top: 0;
}

.presence {
  margin-bottom: 4px;
  margin-top: 24px;
  font-weight: 500;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
}

.status, .status span, .icon {
  font-size: 14px;
}

.status span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  margin: 0 8px;
}

.complete {
  color: color-success;
  font-weight: 500;
}

.uncomplete {
  color: color-error;
  font-weight: 500;
}

.button {
  max-width: 150px;
}

.choice {
  margin-top: 16px;
}

.choice div + div {
  margin-top: 8px;
}
