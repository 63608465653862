.container {
    padding: 36px;
}

.title {
    margin: 0 0 16px;
    font-size: 24px;
}

.list {
    padding-left: 0;
}

.plan {
    background-color: white;
    border-radius: 5px;
    padding: 16px;
    width: max-content;
    min-width: 320px;
}

.invitation {
    font-weight: 500;
    margin-top: 0;
    font-size: 18px;
}

.row {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    row-gap: 16px;
}

.row p {
    margin-top: 0;
}

.link {
    font-weight: 500;
    text-decoration: none;
    margin-right: auto;
}
