@value colors: "../../components/library/style/colors.css";
@value color-text-subtle, color-gray, color-gray-light, color-purple, color-purple-bg from colors;

.section {
  width: 100%;
}

.main {
  padding: 0 2.5% 2.5%;
}

.title {
  font-size: 24px;
  margin-top: 24px;
  margin-bottom: 0;
}

.description {
  margin-top: 8px;
}

.card {
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid color-gray-light;
  max-width: 320px;
}

.card .title {
  font-weight: 500;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}

.card .description {
  margin-top: 0;
  color: color-text-subtle;
}

.list {
  list-style: none;
  padding-left: 0;
}

.list li {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  font-size: 14px;
  color: color-text-subtle;
  margin-right: 8px;
}

.list a {
  font-size: 16px;
}

.row {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
}

.documentation {
  background-color: white;
  width: 100%;
  padding: 24px;
  border-radius: 5px;
}

.documentation h2 {
  margin: 0;
  font-size: 24px;
}

.documentation h3 {
  font-size: 20px;
  margin-bottom: 0;
}

.description {
  color: color-text-subtle;
}

.link {
  display: flex;
  align-items: center;
  color: color-purple;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  margin-right: 4px;
  margin-left: 4px;
}

.link .icon {
  color: color-purple;
  font-size: 18px;
  margin-left: 4px;
  margin-right: 2px;
}

.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.warning {
  background-color: color-purple-bg;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.warningIcon {
  color: color-purple;
  margin-right: 8px;
}

.paragraph {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.textIcon {
  color: color-text-subtle;
  font-size: 16px;
  margin: 4px;
}

.step {
  font-weight: 500;
}

.span {
  margin-left: 4px;
  margin-right: 4px;
}
