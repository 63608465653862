.grid {
  grid-area: c;
  height: 100%;
}

.documentsList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 16px;
  padding: 0;
  margin-top: 2rem;
  margin-bottom: 0;
}

@media screen and (max-width: 400px) {
  .documentsList {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
