@value colors: "../../../components/library/style/colors.css";
@value color-red, color-red-dark from colors;

.popUpContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000d9;

  display: flex;
  justify-content: center;
  align-items: center;
}

.popUp {
  background-color: white;
  padding: 24px 16px;
  width: 65%;
  max-width: 750px;
  min-height: 350px;
  border-radius: 5px;
}

.popUp h1 {
  margin: 0;
  font-size: 28px;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.buttonGroup button + button {
  margin-left: 16px;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}

.delete {
  background-color: color-red;
  color: white;
}

.delete:hover {
  background-color: color-red-dark;
}
