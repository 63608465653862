@value colors: "../../library/style/colors.css";
@value color-purple, color-purple-dark from colors;

.vote {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  background-color: #00000096;
}

.popup {
  width: 50%;
  max-width: 900px;
  min-width: 320px;
  padding: 24px;
  box-sizing: border-box;

  background-color: white;
  border-radius: 5px;
}

.popup h2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.resolution {
  margin-top: 24px;
}

.resolution h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

.resolution p {
  margin-top: 4px;
  margin-bottom: 0;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin-top: 8px;
}

.buttonGroup button + button {
  margin-left: 8px;
}

.button {
  background: none;
  border: 1px solid #8E8E8E;
  color: #8E8E8E;
  font-size: 16px;
  padding: 8px 16px;
  transition: .3s;
}

.button:hover {
  border-color: color-purple;
  color: color-purple;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-top: 24px;
}

.selected {
  background-color: color-purple;
  color: white;
  border-color: color-purple;
}

.selected:hover {
  color: white;
  background-color: color-purple-dark;
}

.footer button + button {
  margin-left: 8px;
}
