.list {
  padding-left: 0;
  margin-top: 0;
}

.title, .row {
  background-color: white;
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-radius: 5px;
}

.title {
  font-weight: 500;
  margin-bottom: 16px;
}

.title p, .row p {
  margin: 0;
}

.row + .row {
  margin-top: 8px;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.verified {
  background-color: #E2FAF1;
  color: #02C57E;
}

.unverified {
  background-color: #FBE2DC;
  color: #DE330D;
}

.verified, .unverified {
  max-width: 50px;
  padding: 4px;
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 16px !important;
  margin-right: 8px;
}

.buttonIcon {
  font-size: 14px;
  margin-left: 8px;
}

.label {
  display: none;
}

@media screen and (max-width: 400px) {
  .title { display: none; }

  .row {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 4px;
  }

  .label {
    display: inline-block;
    margin-right: 4px;
    font-weight: 500;
  }
}