@value colors: "../../../components/library/style/colors.css";
@value color-gray from colors;

.form h2 {
    font-size: 18px;
}

.label {
    margin-bottom: 8px;
}

.input {
    height: 48px;
    padding: 0 8px;
    box-sizing: border-box;

    outline: none;
    border-radius: 5px;
    border: 1px solid color-gray;

    transition: .3s;
}

.button {
    margin-top: 16px;
}

.input:hover {
    border-color: #526AF2;
}

.input:focus {
    border-color: #526AF2;
    box-shadow: 0px 0px 6px #b1bdff;
}