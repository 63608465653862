@value colors: "../../library/style/colors.css";
@value color-purple, color-purple-bg, color-purple-dark, color-purple-light from colors;

@value breakpoints: "../../library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.backButton {
    display: none;
}

.chatRoom {
    position: sticky;
    top: 16px;
    width: 65%;
    height: 100%;
    min-height: 80vh;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chatRoomDashboard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chatRoomDashboard .messageOverflow {
    height: 100%;
    max-height: 350px;
}

.header {
    border-bottom: 1px solid #C4C4C4;
    padding: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.roomLogo, .roomPicture {
    width: 48px;
    height: 48px;

    border-radius: 5px;

    margin-right: 16px;
    margin-left: 0;
}

.roomPicture {
    object-fit: cover;
}

.roomLogo {
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(90deg, rgba(79,103,243,1) 0%, rgba(126,145,255,1) 100%);
    color: white;
}

.description {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    font-size: 12px;
    color: #72757E;
    text-align: left;
    margin: 0;
}

.subtitle {
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    margin:0;
}

.roomInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chat {
    padding: 16px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.messageOverflow {
    width: 100%;
    max-height: 55vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.messageOverflow::-webkit-scrollbar {
    width: 4px;               /* width of the entire scrollbar */
}

.messageOverflow::-webkit-scrollbar-track {
    background: transparent;        /* color of the tracking area */
}

.messageOverflow::-webkit-scrollbar-thumb {
    background-color: #C4C4C4;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid transparent;  /* creates padding around scroll thumb */
}

.conversationContainer {
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input {
    width: calc(100% - 52px);
    height: 48px;
    padding: 0 8px;
    background-color: #F4F5F7;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
}

.button {
    width: 48px;
    height: 48px;
    background-color: color-purple;
    color: white;
    border-radius: 5px;
}

.button:hover {
    background-color: color-purple-dark;
}

.button:focus {
    background-color: color-purple-light;
}

.messageContainer {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.messageContainer.send {
    align-items: flex-end;
}

.messageContainer.receive {
    align-items: flex-start;
}
.message {
    padding: 8px;
    font-size: 14px;
    width: fit-content;
    margin: 0;
}

.message.send {
    background: #EBEEFF;
    border-radius: 15px 15px 0px 15px;
    align-items: flex-end;
}

.message.receive {
    background: #F0F0F0;
    border-radius: 15px 15px 15px 0px;
}

.messageContainer.send {
    justify-content: flex-end;
}

.name {
    margin: 0;
    font-size: 12px;
    color: color-purple;
    font-weight: 500;

}

.messagePicture {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    margin-right: 16px;
    align-self: flex-end;
}

@media screen and (max-width: tablet-l) {
    .chatRoom {
        width: 100%;
    }

    .backButton {
        display: block;
        margin-right: 16px;
    }
}
