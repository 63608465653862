@value breakpoints: "../../library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

@value colors: "../../library/style/colors.css";
@value color-purple-bg, color-purple from colors;


.container {
    padding: 16px;
    box-sizing: border-box;

    background-color: white;
    border-radius: 5px;

}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
}

.link {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4F67F3;

    text-decoration: none;
}

.list {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
}

.item {
    all: unset;
    cursor: pointer;
}

.item:hover .documentTitle {
    background-color: color-purple-bg;
    border: 1px solid color-purple;
    color: color-purple;
}

.documentTitle {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 8px;
    padding: 8px;
    border-radius: 5px;

    border: 1px solid color-purple-bg;
}

.document, .date {
    margin: 0;
}

.document {
    color: black;
    text-align: left;
}

.date {
    color: gray;
    text-align: left;
}

.item:hover .document, .item:hover .date {
    color: color-purple;
}

.icon {
    font-size: 24px;
    margin-right: 24px;
}

@media screen and (max-width: tablet-l) {
    .container { grid-area: d }
}
