@value colors: "../../library/style/colors.css";
@value color-purple-bg, color-purple, color-purple-dark, color-error from colors;

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row > div {
  width: 49%;
}

.button {
  margin-top: 16px;
}

.label {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.select {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #C4C4C4;
  height: 42px;
  box-sizing: border-box;
}

.select:hover, .select:focus {
  border-color: color-purple;
  outline: none;
}

.select:focus {
  box-shadow: 0 0 6px #b1bdff;
}

.required {
  margin: 16px 0 0;
}

.required span {
  color: color-purple;
  margin-right: 4px;
}
