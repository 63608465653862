.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
}

.parameter {
  background-color: white;
  border-radius: 5px;
  padding: 8px;
  width: 250px;
}

.parameterTitle {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 0;
}

.number {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;

  margin: 0;
  text-align: center;
}

.date {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #838383;

  float: right;
  margin-bottom: 0;
}
