@value colors: "../../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-text-subtle, color-purple-light, color-purple-bg, color-gray-light from colors;

.choices {
  padding: 24px 24px 0;
  box-sizing: border-box;
  min-height: calc(100vh - 79px - 48px);
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.link {
  all: unset;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: color-purple;
}

.content {
  width: 100%;
  height: 100%;

  background-color: white;
  border-radius: 5px 0;

  margin-top: 24px;
  padding: 16px;
  box-sizing: border-box;
}

.content h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  margin-top: 24px;
}

.choice {
  all: unset;
  background-color: color-purple-bg;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 16px;
  cursor: pointer;
  transition: .3s;

  color: black;
}

.choice:hover, .choice:focus {
  background-color: color-purple-light;
  color: white;
}

.disabled {
  background-color: color-gray-light;
  cursor: not-allowed;
  pointer-events: none;
}

.choice h4 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin: 8px 0;
}

.choice p {
  margin: 0;
}

.iconContainer {
  background-color: white;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  margin-bottom: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: color-purple;
  overflow: hidden;
}

.link {
  cursor: pointer;
}

.logo {
  width: 100%;
}

.background {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #00000059;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}

.popup {
  background-color: white;
  border-radius: 5px;
  padding: 24px;

  max-width: 700px;
  min-width: 320px;
}

.popup h1 {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 0;
}

.popup p {
  color: color-text-subtle;
  margin-top: 4px;
}

.popup div {
  margin: auto;
}

.error, .errorTitle, .center {
  text-align: center;
  margin: auto;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 16px;
}

.buttonError {
  width: 100%;
  margin-top: 24px;
}

.warning {
  color: color-purple-dark;
  background-color: color-purple-bg;
  padding: 8px;
  border-radius: 4px;
  width: max-content;
  display: flex;
}

.icon {
  margin-right: 8px;
}

@media screen and (max-width: 400px) {
  .row {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 16px;
  }
}
