@value colors: "../../style/colors.css";
@value color-gray, color-gray-light from colors;

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
}

.row > div {
    width: 50%;
}

.tax {
    max-width: 200px;
}

.disabled {
    border-color: color-gray;
    background-color: #F1F1F4;
}

.label {
    display: block;
    margin-top: 16px;
}

@media screen and (max-width: 425px) {
    .row { flex-direction: column; }
    .row > div { width: 100%; }
}
