@value colors: "../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-bg from colors;

@value breakpoints: "../../components/library/style/breakpoint.css";
@value laptop, tablet, mobile from breakpoints;

.contact {
  width: 100%;
}

.content {
  padding: 24px 48px;
}

.content h3 {
  margin-bottom: 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.header {
  background-color: white;
  border-bottom: 1px solid #E4E4E4;

  padding: 0 2.5%;
  width: 100%;
  box-sizing: border-box;
  height: 75px;
  margin: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.header a {
  all: unset;
}

.header h1 {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}

.content h2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}

.separator {
  border-right: 1px solid #E4E4E4;
  height: 70%;
  margin: 0 24px;
}

.feedback {
  margin-top: 48px;
}

.feedbackLink {
  margin-left: 4px;
  font-size: 16px;
}

.passive {
  color: black;
  transition: .3s;
}

.passive:hover {
  color: color-purple-dark;
}

@media screen and (max-width: tablet) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: mobile) {
  .content {
    padding: 16px;
  }

  .header h1, .separator {
    display: none;
  }
  .header {
    justify-content: flex-end;
  }

  .header button:first-child {
    margin-right: 8px;
  }
}
