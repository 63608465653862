.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row > div {
  width: 49%;
}

.title {
  margin: 0;
}

.form {
  margin-bottom: 24px;
  margin-top: 8px;
}

.success {
  max-width: 750px;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.success button {
  margin-top: 16px;
}
