@value breakpoints: "../../components/library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.documents {
    width: 100%;
}

.button {
    width: 365px;
}

.button.add {
    margin-right: 8px;
}

.wrapper {
    display: flex;
    flex-direction: row;
}

.wrapper section:first-child {
    margin-right: 40px;
}

.content {
    padding: 24px;
}

.download {
    width: max-content;
    margin-top: 0.5rem;
    margin-right: 0;
    margin-left: auto;
}

@media screen and (max-width: tablet-l) {
  .wrapper {
    flex-direction: column;
  }

  .wrapper section:first-child {
      margin-right: 0;
  }

}
