@value colors: "../../components/library/style/colors.css";
@value color-purple-dark from colors;

@value breakpoints: "../../components/library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.report {
    width: 100%;
    max-width: 850px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.report a {
    width: 70% !important;
}

.tools {
    width: 30%;
    margin-bottom: 16px;
    margin-left: 8px;
}

.edit {
    color: black;
}

.delete:hover {
    color: color-purple-dark;
}

.confirmation {
    padding: 16px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media screen and (max-width: tablet) {
  .tools {
    width: unset;
  }
}
