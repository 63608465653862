.content {
  padding: 16px;
}
.row {
  display: grid;
  grid-template-columns: 0.25fr 1fr 1fr 2fr 1fr;
  margin-bottom: 8px;
  background-color: white;
  padding: 16px 32px;
  border-radius: 4px;
}

.row p {
  margin: 0;
}

.label {
  text-transform: capitalize;
  font-weight: 500;
}

.number {
  text-align: right;
}
