@value breakpoints: "components/library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

@value colors: "components/library/style/colors.css";
@value color-purple from colors;

.table {
  padding: 0 24px;
}

.onlyDesktop {
  display: block;
}

.tableRow, .tableRowTotal, .tableRowTitle {
  display: grid;
  grid-template-columns: 0.25fr 2fr 2fr 2fr 2fr;
  padding: 0 8px;
}

.tableRowTitle, .tableRow {
  background-color: white;
  border-radius: 5px;
}

.tableRow {
  box-sizing: border-box;
  border-left: 4px solid #FBFBFB;
  transition: .2s;
  margin-bottom: 8px;
}

.tableRow:hover {
  border-left: 4px solid color-purple;
  background-color: white;
}

.tableCellTitle {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.list {
  padding-left: 0;
}

.tableCellTotal {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #72757E;
  text-transform: uppercase;
}

.tableCellTotalNumber {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4F67F3;
}

.tableCellIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.circleButton {
  display: none;
}

.tableRow:hover .circleButton {
  display: block;
}

.chevron {
  font-size: 16px;
}

.category {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.category button {
  font-size: 12px;
  margin-left: 8px;
}

.categorySelector {
  width: 24px;
  height: 24px;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  margin-right: 8px;
  cursor: pointer;
  transition: .3s;
  color: white;
}

.categorySelector:hover, .categorySelector:focus, .categorySelector.selected {
  border-color: color-purple;
}

.categorySelector.selected {
  background-color: color-purple;
}

.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter:not(:last-child) {
  margin-bottom: 8px;
}

.filterDropdown {
  position: absolute;

  background-color: white;
  box-shadow: 0px 0px 16px 8px rgba(39, 48, 101, 0.04);

  padding: 8px;
  border-radius: 5px;

  margin: 0;
}

.filter p {
  margin: 0;
}

@media screen and (max-width: laptop) {
  .onlyDesktop {
    display: none;
  }

  .tableRow, .tableRowTotal, .tableRowTitle {
    display: grid;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 0.25fr;
    padding: 0 8px;
  }
}
