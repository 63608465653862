.capitalisation {
  width: 100%;
  margin-top: 16px;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.graph {
  display: flex;
  flex-direction: row;
}

.graphContent {
  height: 32px;
  transition: .3s;
}

.category:last-child .graphContent {
  border-radius: 0 5px 5px 0;
}

.category:first-child .graphContent {
  border-radius: 5px 0 0 5px;
}

.category:only-child .graphContent {
  border-radius: 5px;
}

.category p {
  margin-top: 4px;
  margin-bottom: 0;
}

.clip {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hovered .clip {
  direction: rtl;
  overflow: visible;
  text-overflow: unset;
}

.notHovered .clip {
  display: none;
}

.notHovered .graphContent {
  opacity: .5;
}

.hovered .graphContent {
  height: 35px;
}
