@value colors: "../../../components/library/style/colors.css";
@value color-purple, color-purple-bg from colors;

.register {
  padding: 24px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
}

.header h2 {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}

.center {
  width: 100%;
  height: 65vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center h3 {
  color: #8E8E8E;
  text-align: center;
}

.plan {
  text-align: center;
}

/*** ASSEMBLY REGISTER ARCHIVE ***/

.list {
  width: 100%;
  list-style: none;
  padding-left: 0;
}

.archiveButton {
  width: 100%;
  padding: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: white;
  border-radius: 5px;
}

.iconContainer {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: color-purple-bg;
  color: color-purple;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  margin: 0;
}

.documentsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  background-color: white;
  margin-top: 16px;
  padding: 16px;
}

.documentButton {
  background-color: color-purple-bg;
  color: color-purple;
  font-size: 36px;;
  width: 115px;
  height: 100px;
}

.documentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.documentTitle {
  margin-top: 4px;
  margin-bottom: 0;
}

.list + .list {
  margin-top: 16px;
}
