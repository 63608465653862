@value colors: "../../library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-bg from colors;

@value breakpoints: "../../library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.folders h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #72757E;
    margin-bottom: 8px;
}

.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    list-style: none;
    padding-left: 0;
}

.list .item:last-child {
    margin-right: 0;
}

.item {
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: color-purple-bg;
    color: color-purple;
    text-decoration: none;
    border: 1px solid color-purple-bg;

    width: 250px;
    padding: 4px 16px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 16px;
    margin-bottom: 8px;

    cursor: pointer;
    transition: .3s;
}

.item:hover {
    border: 1px solid color-purple;
}

.item p {
    text-transform: capitalize;
}

.folderIcon {
    margin-right: 8px;
}

.item.skeleton {
    background-color: #E8E8E8;
    border-radius: 5px;
    padding: 8px;
    transition: .3s;
    margin-bottom: 8px;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: skeleton;
    width: 300px;
    height: 60px;
}

.item.skeleton:hover {
    cursor: default;
    background-color: #E8E8E8;
    border: none;
}

@keyframes skeleton {
    0% {
        background-color: #E8E8E8;
    }
    50% {
        background-color: #D8D8D8;
    }
    100% {
        background-color: #E8E8E8;
    }
}

@media screen and (max-width: mobile) {
  .item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
}
