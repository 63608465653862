.border {
  background-color: white;

  width: 80%;
  height: 48px;
  max-width: 775px;

  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  border-radius: 5px;

  padding: 8px;
  margin-right: 16px;

  display: flex;
  align-items: center;
}

.icon {
    color: #72757E;
    margin-right: 8px;
}

.input {
    all: unset;
    width: 100%;
}

@media screen and (max-width: 768px) {
  .border {
    display: none;
  }
}
