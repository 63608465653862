@value colors: "components/library/style/colors.css";
@value color-text-subtle from colors;

.title {
  font-weight: 500;
  margin-bottom: 0;
}

.description {
  margin-top: 8px;
  color: color-text-subtle;
}

.form {
  max-width: 750px;
}

.submit {
  margin-top: 16px;
  margin-right: 0;
  margin-left: auto;
}
