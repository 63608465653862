.toast {
    position: fixed;
    top: 24px;
    min-width: 400px;
    left: calc(50% - 200px);
    padding: 8px 32px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 500;
}

.toast p {
    margin: 0;
}

.toastIcon {
    margin-right: 8px;
}

.success {
    background-color: #00c773;
}

.failed {
    background-color: #c40000;
}