@value colors: "../../library/style/colors.css";
@value color-error from colors;

.grid {
  grid-area: b;
  margin-bottom: 0;
}

.list {
  display: flex;
  flex-direction: column;
}

.description {
  width: 100%;
  max-width: 750px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
