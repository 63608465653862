@value colors: "../../../components/library/style/colors.css";
@value color-purple, color-purple-bg, color-red-light from colors;

.main {
  display: flex;
  flex-direction: row;
}

.form {
  width: 50%;
  min-width: 550px;
  margin-right: 40px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row > div:first-child {
  width: 65%;
}

.time {
  width: 30%;
}

.label {
  margin-bottom: 8px;
  text-align: left;
}

.timeInput {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #AEADBE;
  padding: 4px 16px;
  outline: none;
  transition: .3s;
}

.timeInput:hover {
  border-color: color-purple;
  cursor: pointer;
}

.timeInput:focus {
  box-shadow: 0 0 6px #b1bdff;
}

.hint {
  font-size: 14px;
  text-align: right;
}

.link {
  margin-left: 4px;
  text-decoration: none;
  font-weight: 500;
}

.survey {
  margin-top: 16px;
}

.description {
  background-color: color-purple-bg;
  border-radius: 5px;
  padding: 16px;
}

.description h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

.assemblyButton {
  margin-top: 48px;
}

.submit {
  display: flex;
  justify-content: flex-end;
}

.section {
  margin: 24px 24px 0 24px;
  padding: 16px;
  background-color: white;
}

.timeInput.error {
  border-color: color-red-light;
}

.hint.error {
  color: color-red-light;
  text-align: left;
  font-size: 12px;
}

.success {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.successTitle {
  text-align: center;
  font-size: 24px;
  margin-bottom: 0;
}

@media screen and (max-width: 400px) {
  .main {
    flex-wrap: wrap-reverse;
  }

  .form {
    min-width: unset;
    width: 100%;
    margin-right: 0;
  }

  .row {
    flex-wrap: wrap;
  }

  .row > div:first-child, .time {
    width: 100%;
  }
}
