@value colors: "../../library/style/colors.css";
@value color-purple, color-purple-dark from colors;

.content {
    padding: 24px;
}

.footer {
    background: #F7F7F7;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    padding: 16px 24px;
}

.footer button:first-child {
    margin-right: 16px;
}

.tertiaryButton {
    background-color: white;
    border: 1px solid #72757E;
    color: #72757E;
}

.tertiaryButton:hover {
    background-color: #F7F7F7;
}

.date {
  max-width: 450px;
  margin-bottom: 32px;
}

.date p {
  margin-top: 0;
}

.success {
  padding: 32px;
  min-height: 250px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success .title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 0;
}

.success .description {
  margin-top: 0;
}

.success a {
  all: unset;

  height: 42px;
  padding: 0 16px;
  background-color: color-purple;
  border-radius: 5px;

  color: white;
  font-size: 16px;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: .3s;
}

.success a:hover {
  color:white;
  background-color: color-purple-dark;
}

.publication {
  margin-bottom: 16px;
}
