.documents {
    width: 100%;
}

.button {
    width: 365px;
}

.wrapper {
    display: flex;
    flex-direction: row;
}

.wrapper section:first-child {
    margin-right: 40px;
}

.content {
    padding: 24px;
}