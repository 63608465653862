@value colors: "../../style/colors.css";
@value color-purple, color-purple-hover, color-gray, color-gray-light from colors;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container button {
  outline: none;
  margin-right: 8px;
}

.checkbox {
  width: 25px;
  height: 25px;
  background: none;
}

.disabled {
  background-color: color-gray-light;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid color-gray;
  outline: none;
  cursor: not-allowed;
  pointer-events: none;
}

.unchecked {
  border: 1px solid color-gray;
  border-radius: 2px;
  margin-right: 8px;
  background-color: none;

  transition: background 0.3s;
}

.unchecked:hover {
  cursor: pointer;
}

.label {
  margin-bottom: 0;
  margin-top: 0;
}

.labelDisabled {
  color: color-gray;
}

.checked {
  border: 1px solid color-purple;
  background-color: color-purple;
  border-radius: 2px;

  color: white;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background 0.3s;
}

.checked:hover {
  cursor: pointer;
}

.icon {
  font-size: 14px;
}
