@value colors: "components/library/style/colors.css";
@value color-purple, color-text-subtle from colors;

@value breakpoints: "components/library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.infoContainer, .descriptionContainer, .shareContainer {
    padding: 16px;
    box-sizing: border-box;

    background-color: white;
    border-radius: 5px;
}

.infoContainerPlaceholder, .descriptionContainerPlaceholder, .shareContainerPlaceholder {

}

.infoContainer {
    display: flex;
    justify-content: left;
    align-items: flex-start;

    min-width: 300px;
}

.shareContainer {
    min-width: 250px;
}

.infoContent {
    width: 100%;
    max-width: 300px;
    padding-top: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.infoContent img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    transition: .3s;
}

.infoDescription h1 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 0;
}

.infoDescription {
    margin-left: 8px;
}

.infoDescription p {
    margin-top: 8px;
    margin-bottom: 8px;
}

.socialsList {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.socialsList a {
    color: black;
    transition: .3s;
}

.socialsList a:hover {
    color: color-purple;
}

.header {
    width: 100%;
    min-height: 275px;
}

.header img, .image {
    width: 100%;
    max-height: 275px;
    object-fit: cover;
}

.image {
    min-height: 100px;
    min-width: 100px;
    border-radius: 5px;
}

.image.header {
    display: flex;
    min-height: 275px;
    min-width: unset;
    width: 100%;
    height: 100%;
}

.image.header.default {
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.image.default {
    background-color: #21D4FD;
    background-image: linear-gradient(45deg, #21D4FD 0%, #B721FF 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image.default p {
    font-weight: 500;
    color: white;
    text-transform: uppercase;
}


.societyWrapper {
    width: 95%;
    margin: auto;
    margin-top: 2%;

    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 24px;
}

.descriptionTitle {
    margin: 0;
}

.descriptionLink {
    display: flex;
    align-items: center;
}
.descriptionLink a {
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description {
    margin-bottom: 0;
}

.update {
    color: color-text-subtle;
    font-style: italic;
    margin-bottom: 0;
}

.shareWrapper {
    display: flex;
    justify-content: space-between;
    max-width: 250px;
}
.shareTitle {
    color: color-text-subtle;
    margin-bottom: 0;
}

.shareNumber {
    font-size: 24px;
    margin: 0;
}



@media screen and (max-width: laptop) {
    .societyWrapper {
        display: grid;
        grid-template:
            "a b" auto
            "c b" auto / 1fr 1fr;
        column-gap: 16px;
        margin-bottom: 24px;
    }
    .infoContainer, .descriptionContainer, .shareContainer {
        width: 100%;
        min-width: unset;
    }

    .infoContainer { grid-area: a }

    .shareContainer { grid-area: c }

    .descriptionContainer { grid-area: b }
}

@media screen and (max-width: tablet) {
    .infoContent img {
        width: 50px;
    }
}

@media screen and (max-width: mobile) {
    .societyWrapper {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .infoContent img {
        width: 75px;
        margin-right: 16px;
    }

    .infoContent {
        justify-content: left;
    }
}
