@value colors: "../../style/colors.css";
@value color-purple, color-error from colors;

.label {
  margin-bottom: 8px;
  text-align: left;
}

.hint {
  font-size: 12px;
  margin-top: 4px;
  text-align: left;
}

.required {
  color: color-purple;
}

.inputSuccess {
  border: 1px solid #49AD40;
}

.inputSuccess:focus-within {
  box-shadow: none;
}

.hintSuccess {
  color: #49AD40;
}

.iconSuccess {
  color: #49AD40;
  margin-right: 16px;
}

.inputError {
  border: 1px solid color-error;
}

.inputError:focus-within {
  box-shadow: none;
}

.hintError {
  color: color-error;
}

.iconError {
  color: color-error;
  margin-right: 16px;
}

.inputDisabled {
  background: #F1F1F4;
  border: 1px solid #C4C4C4;
}

.inputDisabled:focus-within {
  box-shadow: none;
}

.iconContainer {
  width: 38px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border-radius: 0 5px 5px 0;
}

.container {
  width: 100%;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.month {
  width: 40%;
}

.separator {
  width: 10%;
  text-align: center;
  font-size: 18px;
}

.year {
  width: 60%;
}
