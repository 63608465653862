@value colors: "components/library/style/colors.css";
@value color-text-subtle, color-gray, color-purple from colors;

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

.title {
  font-weight: 500;
  margin-bottom: 0;
}

.description {
  margin: 8px 0 16px;
  color: color-text-subtle;
}

.number {
  min-width: 24px;
  min-height: 24px;
  border-radius: 24px;
  border: 1px solid color-gray;
  color: color-text-subtle;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

.delete {
  border: 1px solid color-gray;
  height: 42px;
  min-width: 42px;
  border-radius: 5px;
  transition: 0.3s;
}

.delete .icon {
  font-size: 16px;
}

.delete:hover,
.delete:hover .icon {
  color: color-purple;
  border-color: color-purple;
}

.send {
  margin-right: 0;
  margin-left: auto;
}
