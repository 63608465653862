@value colors: "components/library/style/colors.css";
@value color-text-subtle, color-gray, color-purple-bg, color-purple from colors;

.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 1rem 2rem 2rem;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: max-content;
}

.title {
  font-weight: 500;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 4px;
}

.description {
  margin-top: 0;
  max-width: 950px;
  color: color-text-subtle;
}

.row {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

.offer {
  padding: 1rem;
  min-width: 300px;
  box-sizing: border-box;
  border: 1px solid color-gray;
  border-radius: 5px;
}

.offer .title {
  font-size: 16px;
  margin-bottom: 16px;
}

.own {
  margin-left: 8px;
  font-size: 12px;
  padding: 0.25rem;
  border-radius: 2px;
  background-color: color-purple-bg;
  color: color-purple;
}

.separator {
  border: none;
  border-top: 1px solid color-gray;
  margin-top: 32px;
  margin-bottom: 32px;
}

.benefit {
  font-size: 12px;
}

.icon {
  font-size: 14px !important;
  margin-right: 8px;
}

.offer .description {
  margin-bottom: 0;
  font-size: 14px;
}

.subscribe {
  width: 100%;
}

.more {
  margin-top: 0;
  min-height: unset;
  height: unset;
  margin-bottom: 16px;
}


.close {
  display: block;
  margin: 0 0 0 auto;
  padding: 0;
}

.close svg {
  color: color-text-subtle;
}
