@value colors: "../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-light, color-purple-bg from colors;

.register {
  width: 100%;
}

.content {
  padding: 24px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.movement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 24px;
}

.movement p, .movement button {
  margin: 0;
}

.movement p {
  display: flex;
  align-items: center;
}

.number {
  font-weight: 500;
  margin-right: 4px;
}

.tools {
  width: 50%;

  display: flex;
  flex-direction: row;
}

.tools button {
  font-size: 16px;
  white-space: nowrap;
}

.button {
  all: unset;
  height: 48px;
  border-radius: 5px;
  background-color: #4F67F3;
  font-weight: 500;
  font-size: 16px;
  color: white;
  padding: 4px 16px;
  box-sizing: border-box;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: .3s;
}

.button:hover {
  color: white;
  background-color: color-purple-dark;
}

@media screen and (max-width: 400px) {
  .header {
    flex-wrap: wrap;
    row-gap: 8px;
  }
}
