@value colors: "../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-bg from colors;

@value breakpoints: "../../components/library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.edition {
    width: 100%;
}

.content {
    padding: 32px;
    max-width: 825px;
}

.content h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    margin-top: 0;
    margin-bottom: 32px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.row p:first-child {
    margin-right: 16px;
    margin-left: 0;
}

.row p {
    margin: 0 16px;
}

.label {
    margin-bottom: 8px;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 35%;

    position: fixed;
    bottom: 32px;
    left: 30%;
}

.buttonGroup button:first-child {
    margin-right: 8px;
}

.buttonGroup button {
    width: 50%;
    padding: unset;
}

.button {
    background-color: white;
    border: 2px solid color-purple;
    color: color-purple;
}

.button:hover {
    background-color: color-purple-bg;
    border-color: color-purple-dark;
    color: color-purple-dark;
}

.required {
  color: color-purple;
}

@media screen and (max-width: tablet-l) {
  .buttonGroup {
    width: 70%;
    left: 15%;
  }
}

@media screen and (max-width: mobile) {
  .buttonGroup {
    width: 95%;
    left: 2.5%;
  }
}
