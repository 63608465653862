@value colors: "components/library/style/colors.css";
@value color-purple from colors;

@value breakpoints: "components/library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  width: 100%;
  height: 100vh;

  background-color: #00000059;
  backdrop-filter: blur(25px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  width: 80%;
  max-width: 800px;

  background-color: white;
  border-radius: 5px;
  overflow: hidden;
}

.header {
  background-color: color-purple;
  color:white;

  padding: 24px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.description h2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  color: #FFFFFF;

  margin-top: 0;
  margin-bottom: 8px;
}

.description p {
  margin: 0;
}

@media screen and (max-width: mobile) {
  .main {
    width: 90%;
  }

  .description h2 {
    font-size: 18px;
    margin: 0;
  }

  .description p {
    display: none;
  }
}
