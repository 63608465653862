.row {
  display: flex;
  flex-direction: row;
}

.row div {
  margin-right: 32px;
}

.success {
  max-width: 750px;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.success button {
  margin-top: 16px;
}

.form {
  max-width: 900px;
}
