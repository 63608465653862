@value colors: "../../style/colors.css";
@value base-purple, purple-lighten-5, base-dark-grey, color-red from colors;

.phoneInput {
  width: 98%;
}

.inputPhoneContainer {
  padding-left: 0;
}

.inputPhoneContainer > div {
  width: 100%;
}

.error {
  color: color-red;
}

.iconError {
  margin-right: 4px;
}
