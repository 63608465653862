@value colors: "components/library/style/colors.css";
@value color-purple, color-purple-bg from colors;

@value breakpoints: "components/library/style/breakpoint.css";
@value laptop, tablet, mobile from breakpoints;

.card {
  background: #FFFFFF;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  display: flex;
  flex-direction: row;

  overflow: hidden;
}

.card:hover .colorLines {
  background-color: color-purple;
}

.colorLines {
  background-color: color-purple-bg;

  width: 8px;
  height: 100%;
}

.content {
  padding: 16px;
  width: 100%;
  overflow-x: auto;
  min-width: 320px;
}

.content h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-top: 0;
}

.grid {
  display: grid;
  grid-template: "a b c"
                 "d e e" / 1fr 1fr 1fr;
  grid-gap: 16px;
}

.gridA {
  grid-area: a;
}

.gridB {
  grid-area: b,
}

.gridC {
  grid-area: c;
}

.gridD {
  grid-area: d;
}

.gridE {
  grid-area: e;
  display: flex;
  justify-content: flex-end;
}

.row {
  display: flex;
  flex-direction: row,
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
}

.cell {
  align-self: center;
}

.cell button {
  font-size: 16px;
}

@media screen and (max-width: laptop) {
  .grid {
    display: block;
  }
  .gridE {
    justify-content: flex-start;
  }
}

@media screen and (max-width: tablet) {
  .grid {
    display: grid;
  }
}

@media screen and (max-width: mobile) {
  .grid {
    display: block;
  }
}
