@value colors: "components/library/style/colors.css";
@value color-gray from colors;

.investor {
  padding: 0.5rem;
  border-bottom: 1px solid color-gray;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.investor:last-child {
  border: none;
}
