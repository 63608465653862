@value colors: "../../../components/library/style/colors.css";
@value color-purple-bg, color-success-dark, color-error, color-warning, color-success, color-success-light, color-success-dark, color-text-subtle from colors;

.help {
  margin: 0;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 8px;
  justify-content: space-between;
}

.header h2 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 0;
}

.buttonTools {
  display: flex;
  flex-direction: row;
}

.buttonTools button + button {
  margin-left: 16px;
}

.date {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}

.date p {
  margin: 0;
}

.date button {
  height: unset;
}

.hint {
  margin-top: 0;
  font-size: 14px;
}

.bold {
  font-weight: 500;
  margin-right: 4px;
}

.link {
  font-weight: 500;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row h3 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.row button {
  width: auto;
  height: unset;
}

.resolutions, .documents, .presence {
  background-color: white;
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 16px;
}

.resolution {
  background-color: color-purple-bg;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.resolution p {
  margin: 0;
}

.resolutionsHeader, .documentsHeader {
  margin-bottom: 16px;
}

.link {
  text-decoration: none;
  margin-left: 8px;
}

.fixedRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.date +.date {
  margin-left: 8px;
}

.grid {
  display: grid;
  grid-template:
    "a b b" auto
    "a c c" auto / 1fr 1fr 1fr;
  grid-gap: 16px;
}

.suivi {
  width: 28%;
}

.info {
  width: 70%;
}

.presence {
  grid-area: a;
  height: 100%;
}

.contactInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.contactLogo, .contactPicture {
  width: 48px;
  height: 48px;

  border-radius: 5px;

  margin-right: 16px;
}

.contactPicture {
  object-fit: cover;
}

.contactLogo {
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(90deg, rgba(79,103,243,1) 0%, rgba(126,145,255,1) 100%);
    color: white;
}

.contactList {
  height: 70%;
  padding-left: 0;
}


.subtitle {
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 18px;
}

.description {
  margin: 0;
  font-style: italic;
}

.success, .error, .warn {
  font-size: 14px;
}

.success {
  color: color-success;
}

.error {
  color: color-error;
}

.warn {
  color: color-warning;
}

.contactName {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notification {
  display: flex;
  flex-direction: row;
  align-items: center;

  max-width: 500px;
  padding: 16px;
  background-color: color-success-light;
  border-radius: 5px;
  color: color-success-dark;

  margin-bottom: 16px;
}

.notification p {
  margin: 0;
}

.notificationIcon {
  margin-right: 16px;
}

.toast {
  position: absolute;
  right: 30%;
  top: 48px;
  padding: 16px;
  box-sizing: border-box;
  background-color: color-success-light;
  color: color-success-dark;
  border-radius: 5px;
}

.toast p {
  margin: 0;
}

.invitation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.invitation p {
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  color: color-text-subtle;
}

@media screen and (max-width: 400px) {
  .grid {
    grid-template:
        "a" auto
        "b" auto
        "c" auto / 100%;
    row-gap: 16px;
  }

  .grid div {
    box-sizing: border-box;
  }
}
