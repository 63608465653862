@value colors: "components/library/style/colors.css";
@value color-purple, color-purple-bg from colors;

.content {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.form {
  max-height: 70vh;
  overflow-y: scroll;
}

.button, .disabled {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 90px;
  padding: 16px;
  box-sizing: border-box;

  border: 1px solid #C4C4C4;
  border-radius: 5px;
  color: #72757E;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
  margin-right: 16px;

  border: 1px solid #C4C4C4;
  border-radius: 5px;
}


.label {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

.button:not(:last-child) {
  margin-bottom: 24px;
}

.button:hover {
  border: 1px solid color-purple;
  color: color-purple;
}

.button:hover .iconContainer {
  border: 1px solid color-purple-bg;
  background-color: color-purple-bg;
}

.disabled {
  cursor: not-allowed;
}

.pill {
  background-color: #F7F7F7;
  padding: 0 16px;
  border-radius: 25px;
  font-style: normal;
  font-weight: 500;
  margin-left: 8px;
}

.footer {
  background: #F7F7F7;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  padding: 16px 24px;
}

.footer button:first-child {
  margin-right: 16px;
}

.tertiaryButton {
  background-color: white;
  border: 1px solid #72757E;
  color: #72757E;
}

.tertiaryButton:hover {
  background-color: #F7F7F7;
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.success {
  max-height: 50vh;
  min-height: 250px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.successTitle {
  font-size: 18px;
  margin-top: 0;
}
/**
 * Extracted from: SweetAlert
 * Modified by: Istiak Tridip
 */
.successCheckmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}

.checkIcon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4CAF50;
}

.checkIcon::before {
   top: 3px;
   left: -2px;
   width: 30px;
   transform-origin: 100% 50%;
   border-radius: 100px 0 0 100px;
 }

.checkIcon::after {
   top: 0;
   left: 30px;
   width: 60px;
   transform-origin: 0 50%;
   border-radius: 0 100px 100px 0;
   animation: rotate-circle 4.25s ease-in;
}

.checkIcon::before, .checkIcon::after {
  content: '';
  height: 100px;
  position: absolute;
  background: #FFFFFF;
  transform: rotate(-45deg);
}

.iconLine {
  height: 5px;
  background-color: #4CAF50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

.iconLine.lineTip {
   top: 46px;
   left: 14px;
   width: 25px;
   transform: rotate(45deg);
   animation: icon-line-tip 0.75s;
 }

.iconLine.lineLong {
   top: 38px;
   right: 8px;
   width: 47px;
   transform: rotate(-45deg);
   animation: icon-line-long 0.75s;
 }

.iconCircle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, .5);
}

.iconFix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #FFFFFF;
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
