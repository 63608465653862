@value colors: "components/library/style/colors.css";
@value color-purple, color-purple-dark from colors;

.event {
    overflow-y: auto;
    max-height: 90vh;
}

.content {
    padding: 24px;
}

.img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.row {
    display: flex;
    flex-direction: row;
}

.center {
    justify-content: center;
}

.row button + button {
    margin-left: 16px;
}

.title {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    margin-bottom: 0;
}

.description {
    color: gray;

    margin-top: 8px;
}

.details {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    margin-bottom: 0;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
}

.row p {
    margin-left: 16px;
    margin-bottom: 0;
    margin-top: 0;
}

.button {
    font-size: 14px;
    color: gray;
    font-weight: 400;

    border: 1px solid lightgray;
    border-radius: 2px;
    padding: 16px;

    transition: .3s;
}

.button:hover {
    border-color: color-purple;
    color: color-purple;
}

.row .button:first-child {
    margin-right: 8px;
}

.icon {
    margin-right: 8px;
}

.buttonGroup {
    justify-content: flex-end;
}

.timetable div:first-child {
    margin-right: 16px;
}

.confirmation {
    font-size: 20px;
    text-align: center;
}

.row {
    display: flex;
    flex-direction: row;
}
