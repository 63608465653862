@value breakpoints: "../../components/library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.dashboard { width: 100%; }

.container {
    width: 95%;
    margin: 2% auto;

    display: grid;
    grid-template-columns: 4fr 3fr 3fr;
    grid-gap: 24px;
}

.onboarding {
    font-size: 24px;
    margin-bottom: 0;
}

@media screen and (max-width: tablet-l) {
    .container {
        grid-template:
        "d e" auto
        "f f" auto / 1fr 1fr;
        column-gap: 16px;
    }
}

@media screen and (max-width: mobile) {
    .container {
        width: 90%;
        display: flex;
        flex-direction: column-reverse;
    }
}
