@value colors: "../../components/library/style/colors.css";
@value color-text-subtle, color-purple, color-purple-dark, color-purple-bg from colors;

@value breakpoints: "../../components/library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.report {
    width: 100%;
}

.content {
    padding: 32px;
}

.content h2 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;

    margin-top: 0;
    margin-bottom: 32px;
}

.folders {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
}

.folders .folder:first-child {
    margin-right: 32px;
}
.folder {
    display: flex;
    flex-direction: column;
    transition: .3s;
}

.folder span {
    font-size: 20px;
}

.highlight {
    width: 33%;
    height: 3px;
    background-color: transparent;
    transition: .3s;
}

.folder:hover .highlight, .active .highlight {
    background-color: color-purple;
}

.folder:hover, .active {
    color: color-purple;
}

.list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
}
.list .reportLink {
    all: unset;
    text-align: left;
    color: black;
    cursor: pointer;

    max-width: 750px;
    padding: 16px;
    margin-bottom: 16px;

    border: 1px solid #E4E4E4;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-weight: 500;
}

.publication {
    font-weight: normal;
}

.list .reportLink:hover {
    border-color: color-purple;
    color: color-purple;
    background-color: color-purple-bg;
}

.reportTitle p {
    margin: 0;
}

.period {
    font-size: 14px;
    color: color-text-subtle;
}

.reportLink:hover .period {
    color: color-purple;
}

.empty {
    width: 100%;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.empty .title {
    font-size: 24px;
    font-weight: 500;
    color: color-text-subtle;
}

.empty .button {
    height: 32px;
    padding: 8px 16px;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: color-purple;
    color: white;
    font-weight: 500;
    text-decoration: none;
    transition: .3s;
}

.empty .button:hover {
    background-color: color-purple-dark;
}

@media screen and (max-width: mobile) {
  .content {
    padding: 16px;
  }
}
