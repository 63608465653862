@value colors: "../../../components/library/style/colors.css";
@value color-purple-bg, color-purple, color-error, color-red-light, color-success, color-gray from colors;

.summons {
  width: 100%;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-top: 0;
}

.content {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
}

.spacedRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.subTitle {
  margin: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

.main {
  max-width: 700px;
}

.description {
  background-color: color-purple-bg;
  border-radius: 5px;
  padding: 16px;
  margin: 16px 0;
}

.description h4 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 4px 0;
}

.description p {
  margin-bottom: 0;
  font-size: 16px;
}

.row {
  display: flex;
  flex-direction: row;
}

.inputDate {
  width: 70%;
  margin-right: 5%;
}

.time {
  width: 25%;
}

.label {
  margin-top: 16px;
  margin-bottom: 8px;
}

.timeInput {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #AEADBE;
  padding: 4px 16px;
  outline: none;
  transition: .3s;
}

.timeInput:hover {
  border-color: color-purple;
  cursor: pointer;
}

.timeInput:focus {
  box-shadow: 0 0 6px #b1bdff;
}

.timeInput.error {
  border-color: color-red-light;
}

.hint.error {
  color: color-red-light;
  text-align: left;
  font-size: 12px;
}

.row {
  display: flex;
  flex-direction: row;
}

.row button + button {
  margin-left: 16px;
}

.buttonGroup {
  margin-top: 16px;
}

.resolution {
  padding: 8px;
  background-color: color-purple-bg;
  border-radius: 5px;
  width: 80%;
  box-shadow: 0 4px 4px rgba(0, 27, 81, 0.10);
}

.resolutionText {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resolutionText span {
  font-weight: 500;
  margin-right: 4px;
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.submit button + button {
  margin-left: 16px;
}

.popUpContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000d9;

  display: flex;
  justify-content: center;
  align-items: center;
}

.popUp {
  background-color: white;
  padding: 16px;
  width: 65%;
  max-width: 750px;
  min-height: 350px;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 5px;
}

.popUp h1 {
  margin: 0;
  margin-bottom: 16px;
  font-size: 28px;
}

.resolutionGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}

.inviteButton {
  margin-top: 8px;
}

.inviteHint {
  display: flex;
  flex-direction: row;
  align-items: center;

  color: color-success;
  font-weight: 500;
}

.inviteHintError {
  display: flex;
  flex-direction: row;
  align-items: center;

  color: color-error;
  font-weight: 500;
}

.inviteHint span, .inviteHintError span {
  margin-right: 8px;
}

.outline {
  margin-right: 16px;
  border: 2px solid color-purple;
  text-decoration: none;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 24px;
  box-sizing: border-box;
  color: color-purple;
}

.form {
  width: 55%;
}

.side {
  width: 40%;
}

.dropdown {
  height: 42px;
  width: 100%;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  color: black;
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  font-family: 'Roboto', sans-serif;
}

.buttonGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.editor {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.textEditor {
  margin-top: 16px;
  height: 50vh;
  width: 100%;
}

.textEditor > div {
  height: 100%;
}

.subtitle {
  font-weight: 500;
  margin-bottom: 4px;
}

.hint {
  margin-top: 0;
  font-size: 14px;
}

.warning {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: color-purple-bg;
  border-radius: 5px;
  padding: 8px;
}

.icon {
  color: color-purple;
  margin-right: 16px;
}

.description {
  margin: 0;
}

.hidden {
  display: none;
}

.checkbox + .checkbox {
  margin-top: 16px;
}

.documentsButton {
  max-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: color-purple;
  font-weight: 500;
}

.documentsButton p {
  margin: 8px;
  margin-left: 0;
}

.center {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center h3 {
  margin-top: 8px;
}

.bold {
  font-weight: 500;
}

.selected {
  color: color-purple;
}

.unselected {
  color: color-gray;
}