@value colors: "../style/colors.css";
@value color-gray-light, color-text-subtle from colors;

.toastContainer {
    position: fixed;
    bottom: 12px;
    right: 12px;
    z-index: 1000;
    overflow: hidden;
    width: 33%;
}

.toastContainer .toast + .toast {
    margin-top: 16px;
}

.toast {
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
    background: white;
    border-radius: 5px;
    min-width: 250px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid color-gray-light;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}

.title {
    font-weight: 500;
    font-size: 18px;
    margin: 0;
}

.description {
    color: color-text-subtle;
    margin: 0;
}

.button {
    color: color-text-subtle;
}

.button .icon {
    font-size: 18px;
}

.time {
    height: 4px;
    border-radius: 5px 5px 0 0;
}

.hideToast {
    transition: transform .6s ease-in-out;
    animation: toast-out-right .7s;
}

.textContainer {
    margin: 0 16px;
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);

    }
    to {
        transform: translateX(0);
    }
}

@keyframes toast-out-right {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}
