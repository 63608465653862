@value colors: "components/library/style/colors.css";
@value color-purple from colors;

@value breakpoints: "components/library/style/breakpoint.css";
@value mobile, tablet, tablet-l, laptop, desktop from breakpoints;

.shareholders {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.description {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;

  background: white;

  width: 30%;
  min-width: 500px;
  height: 100vh;

  padding: 40px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overlay {
  width: 100%;
  height: 100vh;

  position: absolute;
  top: 0;
  left: 0;

  background-color: #00000091;
}

.row {
  display: flex;
  flex-direction: row;
}

.rowSpaced {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profilePicture {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;

  margin-right: 24px;
}

.profileName {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;

  margin-top: 0;
  margin-bottom: 0;
}

.profileMail {
  margin-top: 8px;
  margin-bottom: 0;
}

.profilePhone {
  margin-top: 8px;
  margin-bottom: 0;
}

.title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4F67F3;
  margin-top: 48px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.grid p {
  font-size: 16px;
  margin: 0;
}

.list {
  list-style: none;
  padding-left: 0;
}

.list li {
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  align-items: center;
  text-align: left;
}

.documentIcon {
  color: #C4C4C4;
}

.edition {
  align-self: flex-start;
  padding: 4px 16px;
  transition: .3s;

  display: flex;
  flex-direction: row;
}

.edition:hover {
  background-color: color-purple;
  color: white;
  border-radius: 5px;
}

.buttonHover {
  display: none;
  margin-left: 4px;
}

.edition:hover .buttonHover {
  display: block;
}

.close {
  display: none;
}

@media screen and (max-width: mobile) {
  .description {
    width: 100%;
    min-width: unset;
    padding: 16px;
    box-sizing: border-box;
    overflow-y: scroll;
  }

  .edition {
    display: none;
  }

  .close {
    display: block;
    align-self: flex-start;
  }

  .shareholders {
    display: none;
  }
}
