@value colors: "../../style/colors.css";
@value color-error from colors;

.container span {
    margin-right: 4px;
}

.hint {
    font-size: 12px;
    margin-top: 4px;
}

.hint.error {
    color: color-error;
}

.inputError {
    border-color: color-error;
}