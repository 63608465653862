@value colors: "../../components/library/style/colors.css";
@value color-gray from colors;

.section {
    padding: 24px;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
}

.logo {
    font-family: Fjalla One;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    margin: 0;
}

.main {
    min-height: 70vh;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main h1 {
    margin-bottom: 16px;
}

.description {
    font-size: 18px;
    margin: 0;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
}

.buttonGroup button + button {
    margin-left: 16px;
}

.decor {
    position: absolute;
    top: 0;
    font-family: Fjalla One;
    font-style: normal;
    font-weight: normal;
    line-height: 0;
    z-index: 0;
    font-size: 785px;
    line-height: 100%;
    color: #dde2ec;
    opacity: .75;
    margin: 0;
}

.project {
    margin-top: 24px;
}

.project p {
    font-weight: 500;
}

.project ul {
    padding: 0;

    display: flex;
    flex-direction: column;
}